import React, { Fragment, useContext } from 'react';
import { arrayOf, func, node, string, shape } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { PromoPod } from '../PromoPod/PromoPod';
import { PromoPodPlaceholder } from '../PromoPod/subcomponents/PromoPodPlaceholder';
import { ListObserver } from './subcomponents/ListObserver';
import { usePromoModel } from '../../hooks/usePromoModel';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';
import { noop } from '../../utils/promo-utils';
import { PromoListDataModel } from '../../models/PromoListDataModel';

export const PromoList = ({
  itemIds,
  observer,
  listText,
  onProductPodClick,
  placeholderItemIds
}) => {
  const { channel } = useContext(ExperienceContext);
  const storeId = useStoreId();
  const { type } = usePromoPresentation();
  const { anchorItem, isAwaitingData, hasAnchorFromSearch, isBmsm, isCategorizedPromotion} = usePromoModel();

  const showAnchorAtTop = type === 'card' || isBmsm && !isCategorizedPromotion;
  const maxNumPlaceholdersToShow = channel === 'mobile' ? 1 : 3;
  const numPlaceholdersToShow = Math.min(placeholderItemIds.length, maxNumPlaceholdersToShow);

  return (
    <article
      className="sui-flex sui-flex-col sui-gap-1 sui-w-auto sui-overflow-y-auto"
      data-testid="promo-list"
    >
      {showAnchorAtTop && (
        <QueryProvider
          cacheKey="promotion-anchor-item"
          defaultVariables={{ storeId }}
        >
          <PromoPod
            displayPosition={0}
            itemId={anchorItem?.itemId}
            key={anchorItem?.itemId}
            onProductPodClick={onProductPodClick}
            hideCta={type === 'card'}
          />
        </QueryProvider>
      )}
      {listText && (
        <div data-testid="promo-list-text">
          {listText}
        </div>
      )}
      <div className="sui-flex sui-flex-col sui-gap-1 sui--ml-2">
        {itemIds.map((itemId, i) => {
          const displayPosition = String(showAnchorAtTop ? i + 2 : i + 1);
          const shouldUseCatalogForAnchor = Boolean(
            itemId === anchorItem?.itemId && !hasAnchorFromSearch
          );

          return (
            <Fragment key={itemId}>
              {(shouldUseCatalogForAnchor) ? (
                <QueryProvider
                  cacheKey="promotion-anchor-item"
                  defaultVariables={{ storeId }}
                >
                  <PromoPod
                    displayPosition={displayPosition}
                    itemId={itemId}
                    onProductPodClick={onProductPodClick}
                  />
                </QueryProvider>
              ) : (
                <PromoPod
                  displayPosition={displayPosition}
                  itemId={itemId}
                  key={itemId}
                  onProductPodClick={onProductPodClick}
                />
              )}
            </Fragment>
          );
        })}
        <ListObserver pause={isAwaitingData} observer={observer}>
          {placeholderItemIds.slice(0, numPlaceholdersToShow).map((itemId) => (
            (isAwaitingData || itemIds.length === 0) && <PromoPodPlaceholder key={itemId} />
          ))}
        </ListObserver>
      </div>
    </article>
  );
};

PromoList.displayName = 'PromoList';

PromoList.propTypes = {
  itemIds: arrayOf(string),
  observer: shape({}),
  listText: node,
  onProductPodClick: func,
  placeholderItemIds: arrayOf(string),
};

PromoList.defaultProps = {
  itemIds: [],
  observer: null,
  listText: null,
  onProductPodClick: noop,
  placeholderItemIds: [],
};

PromoList.dataModel = PromoListDataModel;
