import { useMemo } from 'react';

import { useDataModel } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { PromoModelProviderDataModel } from '../models/PromoModelProviderDataModel';

export const useSearchProducts = (
  itemIds,
  refinedNvalue,
  skipSearchProducts,
) => {
  const storeId = useStoreId();
  const hasEmptyRequest = !itemIds.length && !refinedNvalue;
  const skip = skipSearchProducts || hasEmptyRequest;

  const graphQlOptions = useMemo(() => {
    const variables = { storeId };
    if (!refinedNvalue) {
      variables.itemIds = itemIds;
    } else {
      variables.navParam = refinedNvalue;
      variables.pageSize = 6;
      variables.storefilter = 'ALL';
    }

    return {
      variables,
      skip,
      dataModel: PromoModelProviderDataModel
    };
  }, [
    itemIds,
    refinedNvalue,
    skip,
    storeId
  ]);

  const { data, loading, error } = useDataModel('promotionProductsItems', graphQlOptions);

  // we memoize this to prevent new array references triggering consumer rerender
  const searchProductsMemo = useMemo(() => {
    const products = data?.searchModel?.products || [];

    return {
      products,
      loading,
      error,
    };
  }, [data, loading, error]);

  return searchProductsMemo;
};
