import { isFulfillable } from './promo-fulfillment-utils';
import {
  getMinimumPrice,
  isForwardsBogo as isForwardsBogoFn,
  isBackwardsBogo as isBackwardsBogoFn,
  isForwardsB1gy as isForwardsB1gyFn,
  isForwardsBxg1 as isForwardsBxg1Fn,
  isDollarThresholdBogo as isDollarThresholdBogoFn,
  pipe,
} from './promo-utils';
import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS,
  BMSM_SUBEXPERIENCE_TAGS,
  DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS,
  EXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS,
  MA,
  MAX_NUM_TIERS_IN_PROMOTION,
  MSB_SUBEXPERIENCE_TAGS,
  NVALUE_REFINEMENT_SEPARATOR
} from './constants';

const hasDisplayableBogo = (promotion) => {
  const { subExperienceTag, reward } = promotion;

  switch (subExperienceTag) {
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne:
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff:
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff:
    return true;
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
    return Boolean(
      reward.tiers.length === 1
        && reward.tiers[0].minPurchaseQuantity === 1
        && reward.tiers[0].rewardPercent
    );
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
    return Boolean(
      reward.tiers.length === 1
        && reward.tiers[0].minPurchaseQuantity === 1
        && reward.tiers[0].rewardAmountPerOrder
    );
  default:
    return false;
  }
};

/**
 * Given an array of reward tiers, this function will return true
 * if all reward tiers contain *at least* the same properties in the first reward tier.
 * @param {Array} rewardTiers
 * @returns {boolean} hasSameTiers
 */
const allTiersHaveSameDetails = (rewardTiers) => {
  const {
    minPurchaseQuantity,
    minPurchaseAmount,
    rewardPercent,
    rewardAmountPerOrder,
    rewardAmountPerItem,
    rewardFixedPrice
  } = rewardTiers[0];

  return rewardTiers.every((tier) => {
    if (rewardPercent && !tier.rewardPercent) return false;
    if (rewardAmountPerItem && !tier.rewardAmountPerItem) return false;
    if (rewardAmountPerOrder && !tier.rewardAmountPerOrder) return false;
    if (minPurchaseAmount && !tier.minPurchaseAmount) return false;
    if (minPurchaseQuantity && !tier.minPurchaseQuantity) return false;
    if (rewardFixedPrice && !tier.rewardFixedPrice) return false;
    return true;
  });
};

const hasDisplayableTiers = (tiers) => {
  const {
    minPurchaseAmount,
    minPurchaseQuantity,
    rewardAmountPerItem,
    rewardAmountPerOrder,
    rewardFixedPrice,
    rewardPercent,
  } = tiers?.[0] || {};
  return (minPurchaseAmount || minPurchaseQuantity)
    && (rewardPercent || rewardAmountPerOrder || rewardAmountPerItem || rewardFixedPrice)
    && allTiersHaveSameDetails(tiers);
};

const hasDisplayableMsb = (tiers) => (
  tiers.length === 1 && hasDisplayableTiers(tiers)
);

const hasDisplayableBmsm = (tiers) => (
  tiers.length >= 2
  && tiers.length <= MAX_NUM_TIERS_IN_PROMOTION
  && !tiers[0].rewardAmountPerItem // product requirement to discourage bad actor abuse
  && hasDisplayableTiers(tiers)
);

/**
 * compatibility criteria for anchor product:
 * has experienceTag matching BOGO, BMSM, MSB.
 * promotion contains items that are displayable online
 * has reward details compatible with the UI
 * Anchor does not have preferred pricing
 *
 * @param {*} conditionalPromotion
 * @returns {boolean}
 */
const hasCompatiblePromotion = (conditionalPromotion, type) => {
  const {
    nvalues, reward = {}, eligibilityCriteria, experienceTag
  } = conditionalPromotion || {};
  const hasOmsSkus = eligibilityCriteria?.every((eligibilityCriterion) => {
    return !!eligibilityCriterion.omsSkus?.length;
  });
  const hasItemIds = eligibilityCriteria?.every((eligibilityCriterion) => {
    return !!eligibilityCriterion.itemIds?.length;
  });
  const tiers = reward?.tiers;
  const hasTiers = Boolean(tiers?.length);
  const hasItems = (nvalues?.length || hasOmsSkus || hasItemIds);
  const isValidBogo = isForwardsBogoFn({ subExperienceTag: conditionalPromotion?.subExperienceTag });
  if (type === 'card' && !isValidBogo) {
    return false;
  }
  if (!hasItems || !hasTiers) {
    return false;
  }

  switch (experienceTag) {
  case EXPERIENCE_TAGS.BOGO:
    return hasDisplayableBogo(conditionalPromotion);
  case EXPERIENCE_TAGS.MSB:
    return hasDisplayableMsb(tiers);
  case EXPERIENCE_TAGS.BMSM:
    return hasDisplayableBmsm(tiers);
  default:
    return false;
  }
};

/**
 * Keep items that have identifiers, pricing, a price greater than or equal to the minimum,
 * @returns {Array} filteredItems
 */
const filterItems = ({ items, minimumPrice }) => items.filter((item) => (
  item?.identifiers
  && item.pricing
  && !item.pricing.preferredPriceFlag
  && item.pricing.value >= minimumPrice
));

const isProductSoldOut = (product) => {
  return product.identifiers?.productType === MA ? false : !isFulfillable(product);
};

const addSoldOut = (product) => {
  return {
    ...product,
    isSoldOut: isProductSoldOut(product),
  };
};

const addSoldOutAndListId = ({ filteredItems, listId }) => (
  filteredItems.map((filteredItem) => ({
    ...filteredItem,
    listId,
    isSoldOut: isProductSoldOut(filteredItem),
  }))
);

const sortAnchorItemToTopOfList = ((items, anchorId) => items.slice().sort((item1, item2) => {
  if (item1.itemId === anchorId) return -1;
  if (item2.itemId === anchorId) return 1;
  return 0;
}));

const collectItemsMap = (items) => {
  const listItemsMap = new Map();
  let listHasFulfillment = false;

  items.forEach((item) => {
    listHasFulfillment = listHasFulfillment || !item.isSoldOut;
    listItemsMap.set(item.itemId, item);
  });

  return {
    listItemIds: [...listItemsMap.keys()],
    listItemsMap,
    listHasFulfillment
  };
};

const getSanitizedRewardTiers = (promotion) => {
  const tiers = promotion.reward?.tiers || [];

  return tiers
    .slice()
    // Sorts promotion tiers from smallest to largest quantity or amount needed to qualify
    .sort((
      { minPurchaseQuantity: aQty, minPurchaseAmount: aAmt },
      { minPurchaseQuantity: bQty, minPurchaseAmount: bAmt }) => {
      return (aQty < bQty || aAmt < bAmt) ? -1 : 1;
    })
    // Guarantees each tier has default value for each key and sets the tier index
    // this changes backend "nulls" to 0. but would we actually prefer the nulls?
    .map((tier, i) => {
      return {
        tier: i,
        minPurchaseAmount: tier.minPurchaseAmount || 0,
        minPurchaseQuantity: tier.minPurchaseQuantity || 0,
        maxAllowedRewardAmount: tier.maxAllowedRewardAmount || 0,
        maxPurchaseQuantity: tier.maxPurchaseQuantity || 0,
        rewardPercent: tier.rewardPercent || 0,
        rewardAmountPerItem: tier.rewardAmountPerItem || 0,
        rewardAmountPerOrder: tier.rewardAmountPerOrder || 0,
        rewardFixedPrice: tier.rewardFixedPrice || 0,
        fixedPriceTotal: Math.ceil(tier.rewardFixedPrice * tier.minPurchaseQuantity) || 0,
      };
    });
};

const getSanitizedItems = ({ items = [], anchorItemId, minimumPrice = 0, listId = 'first' }) => {
  return pipe(
    filterItems,
    (filteredItems) => addSoldOutAndListId({ filteredItems, listId }),
    (groupedItems) => sortAnchorItemToTopOfList(groupedItems, anchorItemId),
    collectItemsMap
  )({ items, minimumPrice });
};

/**
 * Given a supported promotion, this function will return the sub-experience associated with it.
 * Will return an empty string if no sub-experience can be found.
 * @param {Object} promotion
 * @returns {string} sub-experience
 */
const getSubExperience = (promotion) => {
  if (promotion.experienceTag === EXPERIENCE_TAGS.BOGO) return promotion.subExperienceTag;

  const {
    minPurchaseQuantity,
    minPurchaseAmount,
    rewardPercent,
    rewardAmountPerItem,
    rewardAmountPerOrder,
    rewardFixedPrice
  } = promotion.reward?.tiers?.[0] || {};

  // We skip 3 and 6 so combo of min quantity/amount and reward matches MSB
  if (promotion.experienceTag === EXPERIENCE_TAGS.BMSM) {
    // BuyXorMoreGetYPercent
    if (minPurchaseQuantity && rewardPercent) return BMSM_SUBEXPERIENCE_TAGS.BMSM1;
    // BuyXorMoreSaveYDollar
    if (minPurchaseQuantity && rewardAmountPerOrder) return BMSM_SUBEXPERIENCE_TAGS.BMSM2;
    // BuyXDollarGetYPercent
    if (minPurchaseAmount && rewardPercent) return BMSM_SUBEXPERIENCE_TAGS.BMSM4;
    // BuyXDollarGetYDollar
    if (minPurchaseAmount && rewardAmountPerOrder) return BMSM_SUBEXPERIENCE_TAGS.BMSM5;
    // BuyXPayYDollars
    if (minPurchaseQuantity && rewardFixedPrice) return BMSM_SUBEXPERIENCE_TAGS.BMSM7;
  }

  // MSBONLINE
  if (promotion.experienceTag === EXPERIENCE_TAGS.MSB) {
    // BuyXorMoreGetYPercent
    if (minPurchaseQuantity && rewardPercent) return MSB_SUBEXPERIENCE_TAGS.MSB1;
    // BuyXorMoreSaveYDollar
    if (minPurchaseQuantity && rewardAmountPerOrder) return MSB_SUBEXPERIENCE_TAGS.MSB2;
    // BuyXorMoreSaveYDollarEach
    if (minPurchaseQuantity && rewardAmountPerItem) return MSB_SUBEXPERIENCE_TAGS.MSB3;
    // BuyXDollarGetYPercent
    if (minPurchaseAmount && rewardPercent) return MSB_SUBEXPERIENCE_TAGS.MSB4;
    // BuyXDollarGetYDollar
    if (minPurchaseAmount && rewardAmountPerOrder) return MSB_SUBEXPERIENCE_TAGS.MSB5;
    // BuyXDollarGetYDollarEach
    if (minPurchaseAmount && rewardAmountPerItem) return MSB_SUBEXPERIENCE_TAGS.MSB6;
  }

  return '';
};

const getAnalyticsComponentName = ({ isBogo, isMsb, isBmsm }) => {
  return (isBogo && 'bogo')
    || (isMsb && 'multi-sku bulk')
    || (isBmsm && 'buy more save more')
    || '';
};

const getAnalyticsSharedSection = ({ isBogo, isMsb, isBmsm }) => {
  return (isBogo && 'bogo')
    || (isMsb && 'multi-sku')
    || (isBmsm && 'buy more save more')
    || '';
};

const buildRefinedNValue = (originalNvalue = null, refinement) => {
  const refinedNValue = refinement
    ? `${originalNvalue}${NVALUE_REFINEMENT_SEPARATOR}${refinement}`
    : originalNvalue;

  return refinedNValue;
};

const isDiffLessThanAMonth = (endDate) => {
  const currDate = new Date();
  const diffInMilliseconds = endDate - currDate;
  const isPromoExpired = diffInMilliseconds < 0;
  const diffInDays = (diffInMilliseconds / (24 * 60 * 60 * 1000));
  return (!isPromoExpired && diffInDays <= 30);
};

const getExpDate = (endDate) => {
  const expDate = new Date(endDate);
  const expDateArr = expDate.toString().split(' ');
  return { month: expDateArr[1], day: expDate.getDate() };
};

const transformResponseToContextValues = ({
  promotion,
  firstListProducts,
  secondListProducts,
  firstListNextPageItemIds,
  secondListNextPageItemIds,
  anchorItem,
  refinedNvalue,
  maAvailability,
}) => {
  const hasSearchData = firstListProducts.length > 0;
  const hasAnchorFromSearch = firstListProducts.some(({ itemId }) => itemId === anchorItem.itemId)
    || secondListProducts.some(({ itemId }) => itemId === anchorItem.itemId);

  const products = {
    firstList: firstListProducts || [],
    secondList: secondListProducts || [],
  };
  const nextPageItemIds = {
    firstList: firstListNextPageItemIds || [],
    secondList: secondListNextPageItemIds || [],
  };
  const src1EligibilityCriteria = promotion.eligibilityCriteria?.find(
    (criterion) => criterion.itemGroup === 'SRC-1') || {};
  const src1MinPurchaseAmount = src1EligibilityCriteria?.minPurchaseAmount;
  const tgt1EligibilityCriteria = promotion.eligibilityCriteria?.find(
    (criterion) => criterion.itemGroup === 'TGT-1') || {};
  const startDate = promotion.dates?.start;
  const endDate = promotion.dates?.end;
  const isExpiring = endDate && isDiffLessThanAMonth(new Date(endDate));
  const expDate = getExpDate(endDate);
  const shortDesc = promotion.description?.shortDesc || '';
  const longDesc = promotion.description?.longDesc || '';
  const rewardTiers = getSanitizedRewardTiers(promotion);
  const subExperience = getSubExperience(promotion);
  const isBogo = promotion.experienceTag === EXPERIENCE_TAGS.BOGO && !!subExperience;
  const isMsb = promotion.experienceTag === EXPERIENCE_TAGS.MSB && !!subExperience;
  const isBmsm = promotion.experienceTag === EXPERIENCE_TAGS.BMSM && !!subExperience;
  const isForwardsBogo = isForwardsBogoFn({ subExperienceTag: subExperience });
  const isBackwardsBogo = isBackwardsBogoFn({ subExperienceTag: subExperience });
  const isDollarThresholdBogo = isDollarThresholdBogoFn({ subExperienceTag: subExperience });
  const isForwardsB1gy = isForwardsB1gyFn({ subExperienceTag: subExperience });
  const isForwardsBxg1 = isForwardsBxg1Fn({ subExperienceTag: subExperience });
  const anchorProductType = anchorItem.identifiers?.productType;
  const isAnchorAppliance = anchorProductType === MA; // MAJOR_APPLIANCES
  const analyticsComponent = getAnalyticsComponentName({ isBogo, isMsb, isBmsm });
  const analyticsSharedSection = getAnalyticsSharedSection({ isBogo, isMsb, isBmsm });

  // Find greatest rewardFixedPrice if BMSM7
  let minimumPrice = 0;
  if (subExperience === BMSM_SUBEXPERIENCE_TAGS.BMSM7) {
    minimumPrice = getMinimumPrice(rewardTiers);
  }

  // Include anchor item for nValue appliance BMSM promotions
  // this is relying on full product data that only comes from PromotionProductsAnchor
  const updatedFirstListProducts = (
    refinedNvalue
    && !products.firstList.some(({ itemId }) => itemId === anchorItem.itemId)
  )
    ? [anchorItem, ...products.firstList.slice(0, 5)]
    : products.firstList;

  const {
    listItemIds: firstListItemIds,
    listItemsMap: firstListItemsMap,
    listHasFulfillment
  } = getSanitizedItems({
    items: updatedFirstListProducts,
    anchorItemId: anchorItem.itemId,
    minimumPrice
  });

  const {
    listItemIds: secondListItemIds,
    listItemsMap: secondListItemsMap,
  } = getSanitizedItems({
    items: products.secondList,
    anchorItemId: anchorItem.itemId,
    listId: 'second'
  });

  const listItemsMap = new Map([...firstListItemsMap, ...secondListItemsMap]);

  if (!listItemsMap.has(anchorItem.itemId)) {
    listItemsMap.set(anchorItem.itemId, addSoldOut(anchorItem));
  }

  nextPageItemIds.firstList.forEach((itemId) => {
    listItemsMap.set(itemId, {
      itemId,
      showPlaceholder: true,
    });
  });

  nextPageItemIds.secondList.forEach((itemId) => {
    listItemsMap.set(itemId, {
      itemId,
      showPlaceholder: true,
    });
  });

  const isAnchorFulfillable = (isBogo && isAnchorAppliance)
    ? maAvailability.isAvailable
    : !listItemsMap.get(anchorItem.itemId).isSoldOut;

  return {
    allListProductsAndAnchor: listItemsMap,
    hasAnchorFromSearch,
    hasSearchData,
    isForwardsB1gy,
    isForwardsBxg1,
    isBogo,
    isMsb,
    isBmsm,
    isForwardsBogo,
    isBackwardsBogo,
    isDollarThresholdBogo,
    startDate,
    endDate,
    expDate,
    isExpiring,
    shortDesc,
    longDesc,
    analyticsComponent,
    analyticsSharedSection,
    displayableFirstListItemIds: [...firstListItemIds, ...nextPageItemIds.firstList],
    displayableSecondListItemIds: [...secondListItemIds, ...nextPageItemIds.secondList],
    listHasFulfillment,
    isAnchorFulfillable,
    isAnchorAppliance,
    subExperience,
    rewardTiers,
    src1EligibilityCriteria,
    src1MinPurchaseAmount,
    tgt1EligibilityCriteria,
  };
};

export {
  hasCompatiblePromotion,
  transformResponseToContextValues,
  getSanitizedItems,
  buildRefinedNValue,
  getExpDate
};
