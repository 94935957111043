import { useMemo } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { FEATURE_SWITCH_KEYS } from '../utils/constants';
import { PromoModelProviderDataModel } from '../models/PromoModelProviderDataModel';

export const usePromotionProductsPage = (
  promotion,
  eligibilityCriterion,
  startIndex,
  disablePagination,
) => {
  const rewardDrawerMaxLength = useConfigService(FEATURE_SWITCH_KEYS.rewardDrawerMaxLength) || 100;
  const promotionId = promotion?.promotionId;
  const purchaseRequirementGroup = eligibilityCriterion?.itemGroup;
  const totalProducts = eligibilityCriterion?.searchReport?.totalProducts || 0;
  const drawerCountLimit = Math.min(totalProducts, rewardDrawerMaxLength);
  const remainingNumProducts = Math.max(drawerCountLimit - startIndex, 0);
  const pageSize = Math.min(remainingNumProducts, 16);
  const skip = !pageSize || !startIndex || disablePagination;

  const graphQlOptions = useMemo(() => {
    return {
      variables: {
        pageSize,
        promotionId,
        purchaseRequirementGroup,
        startIndex,
      },
      skip,
      dataModel: PromoModelProviderDataModel
    };
  }, [
    pageSize,
    promotionId,
    purchaseRequirementGroup,
    startIndex,
    skip,
  ]);

  const { data, loading } = useDataModel('promotionProductsPage', graphQlOptions);

  const pageMemo = useMemo(() => {
    const pageItemIds = data?.promotionProductsPage?.itemIds || [];

    return {
      pageItemIds,
      loading,
    };
  }, [data, loading]);

  return pageMemo;
};
