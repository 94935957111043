import React from 'react';
import { func } from 'prop-types';
import { ButtonGroup, DrawerBody, DrawerFooter } from '@one-thd/sui-atomic-components';
import { RatingsAndReviewsCondensed } from '@thd-olt-component-react/ratings-and-reviews';
import { useMessage } from '../../../hooks/useMessage';
import { usePromoDrawerModel } from '../../../hooks/usePromoDrawerModel';
import { QuickviewReviewsDrawerDataModel } from '../../../models/QuickviewReviewsDrawerDataModel';
import { MESSAGE_KEY, PRODUCT_CTA_TYPE } from '../../../utils/constants';
import { PromoDrawerHeader } from './subcomponents/PromoDrawerHeader';
import { SeeFullDetailsButton } from '../../SeeFullDetailsButton/SeeFullDetailsButton';
import { PromoSelectCta } from '../../PromoSelectCta/PromoSelectCta';
import { AddToCartCta } from '../../AddToCartCta/AddToCartCta';
import { useProduct } from '../../../hooks/useProduct';
import { usePromoModel } from '../../../hooks/usePromoModel';

export const QuickviewReviewsDrawer = ({ onBack, onClose }) => {
  const { quickviewItemDisplayPosition, quickviewItemId } = usePromoDrawerModel();
  const {
    isSoldOut,
    productCtaType,
    selected,
    selectProduct
  } = useProduct(quickviewItemId, quickviewItemDisplayPosition);
  const { type, anchorItemId } = usePromoModel();
  const title = useMessage(MESSAGE_KEY.title);
  const isBogoCardAnchorSku = type !== 'card' && quickviewItemId !== anchorItemId;
  const showAddToCartCta = isBogoCardAnchorSku && productCtaType === PRODUCT_CTA_TYPE.addToCart;
  const showPromoSelectButton = isBogoCardAnchorSku && productCtaType === PRODUCT_CTA_TYPE.select;

  return (
    <>
      <PromoDrawerHeader
        onBack={onBack}
        onClose={onClose}
        title={title}
      />
      <DrawerBody>
        <RatingsAndReviewsCondensed itemId={quickviewItemId} />
      </DrawerBody>
      <DrawerFooter data-testid="quickview-drawer-footer">
        <ButtonGroup>
          <SeeFullDetailsButton fullWidth itemId={quickviewItemId} />
          {showPromoSelectButton ? (
            <PromoSelectCta
              isSoldOut={isSoldOut}
              itemId={quickviewItemId}
              onSelect={selectProduct}
              selected={selected}
            />
          ) : <div hidden={true}/>}
          {showAddToCartCta ? (
            <AddToCartCta
              align="center"
              displayPosition={quickviewItemDisplayPosition}
              itemId={quickviewItemId}
            />
          ) : <div hidden={true}/>}
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

QuickviewReviewsDrawer.displayName = 'QuickviewReviewsDrawer';

QuickviewReviewsDrawer.propTypes = {
  onBack: func.isRequired,
  onClose: func.isRequired
};

QuickviewReviewsDrawer.dataModel = QuickviewReviewsDrawerDataModel;
