/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useCallback, useState } from 'react';
import { func } from 'prop-types';
import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  IconButton,
  ProgressBar,
  Typography,
  Alert,
  DrawerHeader
} from '@one-thd/sui-atomic-components';
import { Close, InfoFilled } from '@one-thd/sui-icons';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { useBogoImages } from '../../../hooks/useBogoImages';
import { useCartImages } from '../../../hooks/useCartImages';
import { useMessage } from '../../../hooks/useMessage';
import { usePreviewImages } from '../../../hooks/usePreviewImages';
import { usePromoCart } from '../../../hooks/usePromoCart';
import { usePromoDrawerModel } from '../../../hooks/usePromoDrawerModel';
import { usePromoModel } from '../../../hooks/usePromoModel';
import { usePromoPresentation } from '../../../hooks/usePromoPresentation';
import { usePromoProgress } from '../../../hooks/usePromoProgress';
import { useSelectedItems } from '../../../hooks/useSelectedItems';
import { DRAWER_TYPES, MESSAGE_KEY } from '../../../utils/constants';
import { AddMultipleToCartButton } from '../../AddMultipleToCartButton/AddMultipleToCartButton';
import { BogoProductImageList } from '../../BogoProductImageList/BogoProductImageList';
import { LinkButton } from '../../LinkButton/LinkButton';
import { OverflowContainer } from '../../OverflowContainer/OverflowContainer';
import { ProductImageList } from '../../ProductImageList/ProductImageList';
import { PromoList } from '../../PromoList/PromoList';
import { SegmentedProgressBar } from '../../SegmentedProgressBar/SegmentedProgressBar';
import { ShopAllButton } from '../../ShopAllButton/ShopAllButton';
import { ViewCartButton } from '../../ViewCartButton/ViewCartButton';
import { PromoDrawerHeader } from './subcomponents/PromoDrawerHeader';
import { PromoList1DrawerDataModel } from '../../../models/PromoList1DrawerDataModel';

export const PromoList1Drawer = ({ onClose, onDrawerViewChange }) => {
  const {
    displayableFirstListItemIds,
    isForwardsB1gy,
    isForwardsBxg1,
    isBackwardsBogo,
    isAwaitingData,
    isBmsm,
    isDollarThresholdBogo,
    isForwardsBogo,
    isMsb,
    isMultiSelect,
    refinedNvalue,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    firstListPlaceholderItemIds,
    firstListObserver,
    type,
    isAnchorAppliance
  } = usePromoModel();
  const {
    bogoItemInCart,
    bogoSelectedProduct,
    currentPromoTier,
    isPromoAchieved,
    selectedItemsModel,
    firstListCartQuantity,
    secondListCartQuantity,
    firstListCartValue,
    secondListCartValue,
    firstListCartItemIds,
    promoCartItemIds,
  } = usePromoCart();
  const {
    isRewardEnabled
  } = usePromoPresentation();
  const { qualifyingImages, rewardImages } = useBogoImages();
  const { cartImages } = useCartImages();
  const { previewImages } = usePreviewImages();
  const { progressStatus, progressValue, tieredProgressValue } = usePromoProgress();
  const { selectedItemIds } = useSelectedItems();
  const { setQuickviewItemDisplayPosition, setQuickviewItemId } = usePromoDrawerModel();
  const { isExchangeCustomer } = useThdCustomer() || {};

  const drawerSuccessText = useMessage(MESSAGE_KEY.drawerSuccessText);
  const progressBarEndLabel = useMessage(MESSAGE_KEY.progressBarEndLabel);
  const progressBarStartLabel = useMessage(MESSAGE_KEY.progressBarStartLabel);
  const progressBarStatusText = useMessage(MESSAGE_KEY.progressBarStatusText);
  const progressBarSuccessText = useMessage(MESSAGE_KEY.progressBarSuccessText);
  const podCtaSuccessMessage = useMessage(MESSAGE_KEY.podCtaSuccessMessage);
  const srcListFooterText = useMessage(MESSAGE_KEY.srcListFooterText);
  const srcListSubtitle = useMessage(MESSAGE_KEY.srcListSubtitle);
  const title = useMessage(
    type === 'card' ? MESSAGE_KEY.cardCtaHeaderText : MESSAGE_KEY.title
  );
  const srcProductsText = useMessage(
    MESSAGE_KEY.srcProducts,
    refinedNvalue ? 1000 : displayableFirstListItemIds.length
  );
  const rewardButtonText = useMessage(MESSAGE_KEY.rewardButtonText);
  const missingQualifyDrawer1TextWarning = useMessage(MESSAGE_KEY.missingQualifyDrawer1TextWarning);
  const editRewardsText = useMessage(MESSAGE_KEY.editRewardsText);
  const itemLimitTextInfo = useMessage(MESSAGE_KEY.itemLimitTextInfo);

  const openQuickview = useCallback(({ displayPosition, itemId }) => {
    setQuickviewItemId(itemId);
    setQuickviewItemDisplayPosition(displayPosition);
    onDrawerViewChange(DRAWER_TYPES.QUICKVIEW_DRAWER);
  }, [onDrawerViewChange, setQuickviewItemDisplayPosition, setQuickviewItemId]);

  const openSummaryDrawer = useCallback(() => {
    onDrawerViewChange(DRAWER_TYPES.PROMO_SUMMARY_DRAWER);
  }, [onDrawerViewChange]);

  const openPromoList2Drawer = useCallback(() => {
    onDrawerViewChange(DRAWER_TYPES.PROMO_LIST_2_DRAWER);
  }, [onDrawerViewChange]);

  const bmsmRewardEnabled = currentPromoTier > 0;
  const hasSelectedItems = Boolean(selectedItemsModel?.length);
  const isItemsInCart = promoCartItemIds.length !== 0
    && firstListCartItemIds.every((item) => promoCartItemIds.includes(item));

  const [showItemLimit, setShowItemLimit] = useState(
    isBmsm && !isPromoAchieved && !bmsmRewardEnabled && isAnchorAppliance
  );

  const qualifyingThresholdMet = firstListCartValue >= src1EligibilityCriteria.minPurchaseAmount;
  const rewardThresholdMet = secondListCartValue >= tgt1EligibilityCriteria.minPurchaseAmount;
  const rewardThresholdForQuantityMet = secondListCartQuantity >= tgt1EligibilityCriteria.minPurchaseQuantity;
  const qualifyingThresholdForQuantityMet = firstListCartQuantity >= src1EligibilityCriteria.minPurchaseQuantity;
  const underRewardThresholdForQuantityMet = secondListCartQuantity < tgt1EligibilityCriteria.minPurchaseQuantity;
  const underQualifyingThresholdForQuantityMet = firstListCartQuantity < src1EligibilityCriteria.minPurchaseQuantity;


  const showBogoImageList = isForwardsBogo || isBackwardsBogo || isForwardsB1gy || isForwardsBxg1;
  const showCartImageList = isMsb || isBmsm;
  const showSingleProgressBar = isDollarThresholdBogo || isMsb;
  const showSegmentedProgressBar = isBmsm;
  const showPreviewImageList = isForwardsB1gy
    || isForwardsBxg1 || isDollarThresholdBogo ? secondListCartQuantity === 0 : Boolean(previewImages?.length);
  const showSubtitle = Boolean(srcListSubtitle && !isAwaitingData);
  const showMissingQualifyText = (isForwardsB1gy || isForwardsBxg1)
    && underQualifyingThresholdForQuantityMet && secondListCartQuantity > 0;
  const showSrcListFooterText = (isForwardsB1gy || isForwardsBxg1)
    ? secondListCartQuantity === 0 : srcListFooterText;
  const showEditRewardsButton = (isForwardsB1gy || isForwardsBxg1 || isDollarThresholdBogo) && secondListCartQuantity > 0;
  const dollarThresholdRewardButtonVariant = (qualifyingThresholdMet && rewardThresholdMet && isPromoAchieved)
    ? 'secondary':  'primary';
  const rewardButtonQualifyingVariantCheck = underRewardThresholdForQuantityMet
    && underQualifyingThresholdForQuantityMet ? 'primary' : 'secondary';
  const rewardButtonVariant = underRewardThresholdForQuantityMet
    ? 'secondary' : 'primary' && rewardButtonQualifyingVariantCheck;
  const editRewardButtonVariant = isDollarThresholdBogo? dollarThresholdRewardButtonVariant: rewardButtonVariant;
  const showViewCartButton = ((isForwardsBogo || isBackwardsBogo) && bogoItemInCart)
    || (isMsb && isPromoAchieved && !hasSelectedItems)
    || (isBmsm && bmsmRewardEnabled && !hasSelectedItems)
    || ((isForwardsB1gy || isForwardsBxg1) && qualifyingThresholdForQuantityMet && rewardThresholdForQuantityMet)
    || (isDollarThresholdBogo && isPromoAchieved && qualifyingThresholdMet && rewardThresholdMet);
  const showShopAllButton = (isMsb || isBmsm) && isMultiSelect && !isExchangeCustomer;
  const showAddMultipleToCartButton = Boolean((bogoSelectedProduct || isMultiSelect) && selectedItemIds.length);
  const checkThreshold = isDollarThresholdBogo ? (qualifyingThresholdMet && !rewardThresholdMet) :
  (qualifyingThresholdForQuantityMet && !rewardThresholdForQuantityMet)
  const showRewardButton = (
    isDollarThresholdBogo || (isForwardsB1gy || isForwardsBxg1)
  ) && secondListCartQuantity === 0 && isRewardEnabled && checkThreshold;

  const shouldShowConfirmedIcon = () => {
    if (isForwardsB1gy || isForwardsBxg1) {
      return qualifyingThresholdForQuantityMet && rewardThresholdForQuantityMet && Boolean(podCtaSuccessMessage);
    }
    if (isDollarThresholdBogo) {
      return qualifyingThresholdMet && rewardThresholdMet && Boolean(podCtaSuccessMessage);
    }
    if (isBmsm || isMsb) {
      return ((progressStatus === 'success' && Boolean(progressBarStatusText) && isItemsInCart)
      || (currentPromoTier >= 1 && qualifyingThresholdForQuantityMet
      && isItemsInCart));
    }
    if (isForwardsBogo || isBackwardsBogo) {
      return qualifyingThresholdForQuantityMet && Boolean(drawerSuccessText);
    }
    return false;
  };

  const showConfirmedIcon = shouldShowConfirmedIcon();
  const progressBarText = (isMsb || isBmsm)
    ? progressBarSuccessText : podCtaSuccessMessage || drawerSuccessText;
  const getProgressBarTextFinal = isDollarThresholdBogo ? podCtaSuccessMessage : progressBarText;
  const progressBarTextFinal = (isForwardsB1gy || isForwardsBxg1) ? progressBarStatusText : getProgressBarTextFinal;
  const hasFooterButtons = showShopAllButton
    || showRewardButton
    || showAddMultipleToCartButton
    || showViewCartButton
    || showEditRewardsButton;
  const showFooterButtons = hasFooterButtons || showConfirmedIcon;
  const showFooter = showPreviewImageList || showFooterButtons;

  return (
    <>
      <PromoDrawerHeader
        title={title}
        endAdornment={(
          <div className="sui-flex sui-gap-2">
            <IconButton
              aria-label="Open Promo Summary Drawer"
              color="info"
              onClick={openSummaryDrawer}
              icon={InfoFilled}
            />
            <IconButton
              aria-label="Close drawer"
              onClick={onClose}
              icon={Close}
            />
          </div>
        )}
        data-testid="promo-list-1-header"
      />
      <div className="sui-top-[48px] sui-z-10 sui-sticky">
        <DrawerHeader
          className="sui-flex sui-flex-col sui-px-4 sui-pt-3 sui-bg-primary sui-sticky sui-z-10"
          data-testid="promo-list-1-header-decorations"
        >
          <OverflowContainer>

            {showBogoImageList && (
              <BogoProductImageList
                qualifyingImages={qualifyingImages}
                rewardImages={rewardImages}
                inDrawer1
              />
            )}
          </OverflowContainer>
          {showCartImageList && (
            <ProductImageList
              images={cartImages}
              onImageClick={openQuickview}
              inDrawer1
            />
          )}
          {showSingleProgressBar && (
            <>
              <ProgressBar
                endLabel={progressBarEndLabel}
                startLabel={progressBarStartLabel}
                status={progressStatus}
                value={progressValue}
              />
              <>
                <Typography
                  align="left"
                  color={progressStatus === 'success' ? 'success' : null}
                  variant="body-xs"
                >
                  {progressBarStatusText}
                </Typography>
              </>
            </>
          )}
          {showSegmentedProgressBar && (
            <>
              <Typography
                align="left"
                color={progressStatus === 'success' ? 'success' : null}
                component="span"
                variant="h6"
              >
                {progressBarStatusText}
              </Typography>
              <SegmentedProgressBar
                value={tieredProgressValue}
              />
            </>
          )}
        </DrawerHeader>
      </div>
      <DrawerBody
        className="sui-flex-auto sui-overflow-y-auto sui-bg-primary sui-px-4 sui-pt-2 sui-pb-0"
        data-testid="promo-list-1-drawer"
      >
        {showMissingQualifyText && <Alert status="warning">{missingQualifyDrawer1TextWarning}</Alert>}
        {showItemLimit
          && (
            <Alert Alert onClose={() => setShowItemLimit(false)} status="info">
              {itemLimitTextInfo}
            </Alert>
          )}
        <PromoList
          itemIds={displayableFirstListItemIds}
          listText={showSubtitle && (
            <span className="sui-inline-flex sui-items-center sui-gap-2">
              <Typography variant="h6">{srcListSubtitle}</Typography>
              {srcProductsText && <Typography component="span" variant="body-lg">{srcProductsText}</Typography>}
            </span>
          )}
          onProductPodClick={openQuickview}
          placeholderItemIds={firstListPlaceholderItemIds}
          observer={firstListObserver}
        />
      </DrawerBody>
      {showFooter && (
        <DrawerFooter
          data-testid="promo-list-1-drawer-footer"
          className="sui-bg-primary sui-p-4 sui-relative sui-bottom-0 sui-sticky sui-shadow-sm"
        >
          <div className="sui-flex sui-flex-col sui-gap-2">
            {showPreviewImageList && (
              <>
                {showSrcListFooterText && <Typography variant="h6">{srcListFooterText}</Typography>}
                <div className="sui-flex sui-items-center sui-justify-between sui-gap-4">
                  <OverflowContainer scroll={false}>
                    <ProductImageList
                      images={previewImages}
                      showLocks={
                        isDollarThresholdBogo
                        && isForwardsBxg1
                        && isForwardsB1gy
                        && progressStatus !== 'success'
                      }
                    />
                  </OverflowContainer>
                  <LinkButton
                    onClick={openPromoList2Drawer}
                    data-testid="see-all-button"
                  >
                    See&nbsp;All
                  </LinkButton>
                </div>
              </>
            )}
            {showFooterButtons && (
              <ButtonGroup orientation="vertical">
                {(showConfirmedIcon) ? (
                  <Typography component="span" color="success" height="tight" align="center">
                    {progressBarTextFinal}
                  </Typography>
                ) : <div hidden={true}/>}
                {showShopAllButton ? <ShopAllButton/> : <div hidden={true}/>}
                {showAddMultipleToCartButton ?
                  <AddMultipleToCartButton itemIds={selectedItemIds}/> : <div hidden={true}/>}
                {showRewardButton ?
                  <Button fullWidth onClick={openPromoList2Drawer}>{rewardButtonText}</Button> :
                  <div hidden={true}/>}
                {showViewCartButton ? <ViewCartButton/> : <div hidden={true}/>}
                {showEditRewardsButton ? (
                  <Button fullWidth variant={editRewardButtonVariant}
                          onClick={openPromoList2Drawer}>
                    {editRewardsText}
                  </Button>
                ) : <div hidden={true}/>}
              </ButtonGroup>
            )}
          </div>
        </DrawerFooter>
      )}
    </>
  );
};

PromoList1Drawer.displayName = 'PromoList1Drawer';

PromoList1Drawer.propTypes = {
  onClose: func.isRequired,
  onDrawerViewChange: func.isRequired
};

PromoList1Drawer.dataModel = PromoList1DrawerDataModel;
