import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { TrashCan } from '@one-thd/sui-icons';
import { CardMedia } from '@one-thd/sui-atomic-components'
import { usePromoMessage } from '../../hooks/usePromoMessage';
import { useMessage } from '../../hooks/useMessage';
import { MessagingContainerDataModel } from '../../models/MessagingContainerDataModel';
import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS,
  MESSAGE_KEY,
  MESSAGE_STATE,
  ASSETS_IMAGE_URL
} from '../../utils/constants';
import { usePromoModel } from '../../hooks/usePromoModel';

export const MessagingContainer = () => {
  const {
    subExperience,
    isBmsm
  } = usePromoModel();
  const { messageState } = usePromoMessage();
  const podCtaPrimaryMessage = useMessage(MESSAGE_KEY.podCtaPrimaryMessage);
  const podCtaSecondaryMessage = useMessage(MESSAGE_KEY.podCtaSecondaryMessage);
  const podCtaTertiaryMessage = useMessage(MESSAGE_KEY.podCtaTertiaryMessage);

  let showUnfulfillable = false;
  let showSuccess = false;

  switch (subExperience) {
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
    showUnfulfillable = messageState === MESSAGE_STATE.anchorItemUnfulfillable
        || messageState === MESSAGE_STATE.src1ListUnfulfillable;
    showSuccess = messageState === MESSAGE_STATE.success;
    break;
  default: break;
  }

  const showTrashIcon = showUnfulfillable;
  const showBmsmIcon = isBmsm;

  return (
    <div
      className="sui-grow sui-flex sui-flex-col sui-gap-1 sui-whitespace-pre-wrap"
      data-testid="promo-messaging-container"
    >
      <Typography align="left" component="span" data-testid="promo-primary-message" variant="h5">
        <span className="sui-flex sui-flex-row sui-gap-2">         
          {showBmsmIcon && <CardMedia src={`${ASSETS_IMAGE_URL}bmsm.svg`}  alt="" style={{ width: '24px', height:"24px" }} />}
          {podCtaPrimaryMessage}
        </span>
      </Typography>
      {podCtaSecondaryMessage
        && (
          <Typography align="left" component="span" data-testid="promo-secondary-message">
            <span className="sui-inline-flex sui-justify-center sui-items-center sui-gap-1 sui-flex-wrap">
              {showTrashIcon && <TrashCan color="primary" size="small" />}
              {podCtaSecondaryMessage}
            </span>
          </Typography>
        )}
      {podCtaTertiaryMessage
        && (
          <Typography
            align="left"
            component="span"
            data-testid="promo-tertiary-message"
            variant="body-xs"
          >
            {podCtaTertiaryMessage}
          </Typography>
        )}
    </div>
  );
};

MessagingContainer.displayName = 'MessagingContainer';

MessagingContainer.dataModel = MessagingContainerDataModel;
